import { Button, Col, Row, Breadcrumb } from 'antd';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import Layout from '~/v2/components/layout/Layout';

const Container = dynamic(() => import('../components/other/Container'));

export default function Error() {
  return (
    <Layout title='404 Error'>
      <Container>
        <Breadcrumb separator='>'>
          <Breadcrumb.Item>
            <i className='fas fa-home' />
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item>404 Error</Breadcrumb.Item>
        </Breadcrumb>
        <div className='error'>
          <Row gutter={50}>
            <Col xs={24} md={12}>
              <div className='error-content'>
                <h2>OPPS! THIS PAGE COULD NOT BE FOUND</h2>
                <p>Sorry bit the page you are looking for does not exist, have been removed or name changed</p>
                <Button type='primary' shape='round' className='bg-main-400'>
                  <Link href='/'>
                    <a>Go to homepage</a>
                  </Link>
                </Button>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className='error-img'>
                <img src='/assets/images/pages/404/1.png' alt='404 Image' />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
